import { Box, Button } from "@hexocean/braintrust-ui-components";
import { CannotMakeAction } from "@js/apps/jobs/components/cannot-make-action";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";
import { getEditOfferUrl } from "@js/apps/jobs/utils";
import type { EmployerBidListBid, Job } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { AiInterviewButton, InterviewButton } from "../interview-button";
import { NextRoundButton } from "../next-round-button";
import { RejectButton } from "../reject-button";

import { MakeOfferButton, OfferButton, ViewOfferButton } from "./components";
import { handleApproveTalentClick } from "./handle-approve-talent";

import style from "./style.module.scss";

type BidCTAProps = {
  bid: EmployerBidListBid;
  job: Job;
  onlyInterview?: boolean;
  index?: number;
  closeDrawer?: () => void;
};

export const BidCTAButton = ({
  bid,
  job,
  onlyInterview,
  index,
  closeDrawer,
}: BidCTAProps) => {
  const { refetchBidList } = useBidsContext();
  const canManageJobsOnBehalfOfClient = useCanManageJobsOnBehalfOfClient();
  const { status, current_offer, freelancer } = bid;
  const isTalentApproved = bid.freelancer.approved;
  const freelancerId = freelancer.id;

  switch (status) {
    case ENUMS.BidStatus.NEW:
    case ENUMS.BidStatus.VIEWED: {
      if (
        !isTalentApproved &&
        canManageJobsOnBehalfOfClient &&
        refetchBidList
      ) {
        return (
          <CannotMakeAction job={job}>
            <Button
              shape="squared"
              variant="info-2"
              className={style.bidCtaBtn}
              onClick={() => {
                handleApproveTalentClick(freelancerId, refetchBidList);
                if (closeDrawer) closeDrawer();
              }}
              size="x-small"
            >
              Approve talent
            </Button>
          </CannotMakeAction>
        );
      }

      const InterviewButtonElement = (
        <CannotMakeAction job={job}>
          <InterviewButton
            bid={bid}
            job={job}
            closeDrawer={closeDrawer}
            className={style.bidCtaBtn}
            size="x-small"
          />
        </CannotMakeAction>
      );

      const AiInterviewButtonElement = (
        <CannotMakeAction job={job}>
          <AiInterviewButton
            bid={bid}
            job={job}
            closeDrawer={closeDrawer}
            className={style.bidCtaBtn}
            size="x-small"
          />
        </CannotMakeAction>
      );

      if (onlyInterview) {
        return job.is_ai_interview_enabled
          ? AiInterviewButtonElement
          : InterviewButtonElement;
      }

      return (
        <>
          {job.is_ai_interview_enabled
            ? AiInterviewButtonElement
            : InterviewButtonElement}
          <MakeOfferButton
            bid={bid}
            job={job}
            index={index}
            buttonProps={{ size: "x-small" }}
          >
            Make offer
          </MakeOfferButton>
        </>
      );
    }
    case ENUMS.BidStatus.OFFER_CANCELED:
    case ENUMS.BidStatus.OFFER_EXPIRED:
    case ENUMS.BidStatus.IN_REVIEW_AFTER_AI_INTERVIEW:
    case ENUMS.BidStatus.LIVE_INTERVIEW_AFTER_AI_INTERVIEW:
    case ENUMS.BidStatus.INTERVIEWING: {
      if (bid.interview) {
        const interviewGrade = parseFloat(
          bid.interview?.overall_score?.toString() || "",
        );

        return (
          <Box
            sx={{
              display: "flex",
              whiteSpace: "nowrap",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: 1,
              marginLeft: { sm: "auto" },
            }}
          >
            {bid.interview.status === ENUMS.JobInterviewStatus.ROUND_1 && (
              <NextRoundButton
                bid={bid}
                job={job}
                variant={interviewGrade >= 4 ? "primary" : "white-border-beige"}
                size="x-small"
              />
            )}
            <MakeOfferButton
              bid={bid}
              job={job}
              buttonProps={{
                variant:
                  bid.interview.status === ENUMS.JobInterviewStatus.ROUND_2
                    ? "primary"
                    : "white-border-beige",
                size: "x-small",
              }}
            >
              Make offer
            </MakeOfferButton>
            <RejectButton
              bid={bid}
              job={job}
              variant={
                interviewGrade < 4 && bid.interview.is_completed
                  ? "primary"
                  : "white-border-beige"
              }
              size="x-small"
            />
          </Box>
        );
      }

      return (
        <MakeOfferButton
          bid={bid}
          job={job}
          index={index}
          buttonProps={{ size: "x-small" }}
        >
          Make offer
        </MakeOfferButton>
      );
    }
    case ENUMS.BidStatus.OFFER_DECLINED: {
      return (
        <MakeOfferButton
          bid={bid}
          job={job}
          index={index}
          buttonProps={{ size: "x-small" }}
        >
          Make new offer
        </MakeOfferButton>
      );
    }
    case ENUMS.BidStatus.OFFER_MADE:
    case ENUMS.BidStatus.APPLICATION_IN_REVIEW:
    case ENUMS.BidStatus.OFFER_UPDATED:
    case ENUMS.BidStatus.OFFER_DEPOSIT_PAYMENT_FAILED:
    case ENUMS.BidStatus.OFFER_DEPOSIT_PAYMENT_IN_PROGRESS:
    case ENUMS.BidStatus.OFFER_WAITING_FOR_DEPOSIT: {
      const editUrl = getEditOfferUrl({
        jobId: job.id,
        offerId: current_offer as number,
      });
      const previewUrl = `/jobs/${job.id}/offers/${current_offer}/`;
      return (
        <OfferButton
          editUrl={editUrl}
          previewUrl={previewUrl}
          index={index}
          size="x-small"
        />
      );
    }
    case ENUMS.BidStatus.APPLICATION_CANCELED:
    case ENUMS.BidStatus.REJECTED: {
      return bid.current_offer ? (
        <ViewOfferButton bid={bid} job={job} size="x-small" />
      ) : null;
    }
    case ENUMS.BidStatus.HIRED: {
      if (canManageJobsOnBehalfOfClient) {
        const editUrl = getEditOfferUrl({
          jobId: job.id,
          offerId: current_offer as number,
        });
        const previewUrl = `/jobs/${job.id}/offers/${current_offer}/`;

        return (
          <OfferButton
            editUrl={editUrl}
            previewUrl={previewUrl}
            index={index}
            size="x-small"
          />
        );
      }
      return <ViewOfferButton bid={bid} job={job} size="x-small" />;
    }
    default: {
      assertUnreachable(status);
      return null;
    }
  }
};
