import { useCallback, useState } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useBoostFreelancerBid } from "@js/apps/freelancer/hooks/boost-freelancer-bid";
import { boostPurchaseModalClicked } from "@js/apps/jobs/actions";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";

type BoostModalContentProps = {
  boostCredit: number;
  bidId: number;
};

export const BoostModalContent = ({
  boostCredit,
  bidId,
}: BoostModalContentProps) => {
  const { boostFreelancerBid } = useBoostFreelancerBid();
  const [isBoosting, setIsBoosting] = useState(false);
  const dispatch = useAppDispatch();

  const handleBoostClick = useCallback(async () => {
    dispatch(boostPurchaseModalClicked());
    setIsBoosting(true);
    try {
      await boostFreelancerBid(bidId, () => {
        ModalInstance.close();
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsBoosting(false);
    }
  }, [boostFreelancerBid, bidId, dispatch]);

  const closeModal = () => {
    if (!isBoosting) {
      ModalInstance.close();
    }
  };

  function incrementValue(numerator: number) {
    if (numerator === 0) {
      return "0/3";
    }

    const denominator = Math.ceil(numerator / 3) * 3;
    return `${numerator}/${denominator}`;
  }

  return (
    <Box
      maxWidth={"514px"}
      borderRadius={"8px"}
      display={"flex"}
      flexDirection={"column"}
      py={4}
      px={2}
    >
      <Typography component="p" fontWeight={400} variant="title" size="small">
        Boost this application?
      </Typography>
      <Typography mt={2} component="p" size="medium" fontWeight={400}>
        You have {incrementValue(boostCredit)} boosts available.
      </Typography>
      <Box mt={4} display={"flex"} gap={1}>
        <Button
          variant="secondary"
          onClick={closeModal}
          disabled={isBoosting}
          sx={{
            borderRadius: "8px !important",
            width: "221px !important",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="green"
          onClick={handleBoostClick}
          disabled={isBoosting}
          sx={{
            bgcolor: "#49B989 !important",
            color: "white !important",
            width: "221px !important",
            borderRadius: "8px !important",
          }}
        >
          {isBoosting ? "Boosting..." : "Boost"}
        </Button>
      </Box>
    </Box>
  );
};
